.review-location-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .review-location__title {
    font-size: 1.2rem;
    font-weight: 650;
    height: 10%;
    padding: 5%;
    color: #ff4c6c;
    margin-bottom: 5%;
  }

  .review-location__contents-wrapper {
    width: 90%;
    height: 50%;
    overflow: scroll;

    .review-location__contents {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      button {
        width: 70%;
        // height: 9%;
        border-radius: 1rem;
        margin: 5%;
        padding: 3% 4% 3% 4%;
        border: none;
        cursor: pointer;
        outline: inherit;
        background-color: #ff4c6c;
        color: white;
        font-size: 0.9rem;
        font-weight: 600;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
