#modal.toast {
  position: "fixted";
  bottom: "10%";
  background-color: black;
  color: white;
}

#modal.none {
  display: none;
}
