:root {
  font-size: 20px;
}

html {
  width: 100vw;
  height: 100vh;
  background-color: rgb(226, 226, 226);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  font-family: "NanumSquareRound";
}

body {
  background-color: white;
  max-width: 480px;
  min-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  section {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    main {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}

.none {
  display: none;
}
