.login-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .login-logo {
    margin-bottom: 2em;
  }
  .login-button-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 40%;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      margin: 0.6em;
      width: 90%;
      height: 2.1em;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      color: white;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      &:first-child {
        background-color: #03c75a;
        border-radius: 5px;
      }
      &:last-child {
        background-color: #fee500;
        border-radius: 5px;
      }
    }
  }
}
