html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:root {
  font-size: 20px;
}

html {
  width: 100vw;
  height: 100vh;
  background-color: #e2e2e2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  font-family: "NanumSquareRound";
}

body {
  background-color: white;
  max-width: 480px;
  min-width: 360px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
body section {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
body section main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.none {
  display: none;
}

.toggle-button {
  position: absolute;
  width: 6%;
  height: 2.5%;
  padding: 2%;
  background-color: rgba(255, 76, 108, 0.7);
  top: 4.3%;
  left: 3%;
  border-radius: 5px;
}
.toggle-button img {
  width: 90%;
  height: 90%;
  object-fit: scale-down;
}

.menubar {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
}

.menubar-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: row;
  position: relative;
}
.menubar-wrapper .menubar__overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0);
  transition-property: display, background-color;
  transition: all 0.8s ease-out;
  z-index: 9;
}
.menubar-wrapper .menubar__contents-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: white;
  transition: transform 0.5s ease-out;
  z-index: 10;
  font-size: 1em;
  font-weight: 600;
}
.menubar-wrapper .menubar__contents-wrapper .menubar__contents {
  position: relative;
  width: 100%;
  top: 16%;
  left: 0%;
  display: flex;
  flex-direction: column;
}
.menubar-wrapper .menubar__contents-wrapper .menubar__contents * {
  width: 50%;
  margin-bottom: 3%;
  margin-top: 3%;
  padding: 3%;
  padding-right: 20%;
  padding-left: 30%;
  text-align: center;
  text-decoration: none;
  color: black;
}

.home-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.home-wrapper .map-wrapper {
  background-color: #dfdfdf;
  position: relative;
  z-index: 0;
  top: 0px;
  width: 100%;
  height: 85%;
}
.home-wrapper .map__my-direction-button {
  position: absolute;
  font-weight: 600;
  font-size: 0.8em;
  top: 70%;
  margin-right: 1em;
  right: 0;
  width: 3em;
  height: 3em;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-wrapper .map__my-direction-button img {
  width: 85%;
}
.home-wrapper .search-wrapper {
  width: 80%;
  height: 5%;
  padding-left: 2%;
  background-color: white;
  position: absolute;
  top: 4%;
  left: 15%;
  border-radius: 5px;
  opacity: 0.9;
  display: flex;
  justify-content: space-between;
}
.home-wrapper .search-wrapper .search__keyword {
  width: 80%;
  height: 90%;
  border: none;
  font-size: 0.8em;
  color: black;
  margin: 0;
}
.home-wrapper .search-wrapper .search__keyword:focus {
  outline: none;
}
.home-wrapper .search-wrapper .search__keyword-button {
  border: 0;
  width: 17%;
  height: 90%;
  background-color: white;
  padding: 3%;
}
.home-wrapper .search-wrapper .search__keyword-button img {
  width: 50%;
}
.home-wrapper .recommend-wrapper {
  will-change: transform;
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  top: 78%;
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease-out;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 15px;
}
.home-wrapper .recommend-wrapper .recommend__move-button {
  width: 100%;
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-wrapper .recommend-wrapper .recommend__move-button .recommend__move-button__icon {
  background-color: #e7e3e3;
  width: 20%;
  height: 0.25em;
  border-radius: 3px;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper {
  margin-bottom: 10%;
  margin-top: 5%;
  width: 100%;
  height: 200%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info {
  width: 85%;
  height: 12%;
  margin-top: 2%;
  position: relative;
  padding: 0.5em;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 2%;
  padding-left: 4%;
  font-size: 0.7em;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__name {
  margin-top: 2%;
  margin-bottom: 5%;
  font-weight: 600;
  font-size: 1em;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__comment {
  width: 10%;
  margin-bottom: 4%;
  font-weight: 600;
  color: #5a3307;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__bookmark {
  position: absolute;
  padding: 0;
  top: 0;
  left: 84%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__bookmark img {
  object-fit: contain;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__star {
  width: 50%;
  height: 10%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__star img {
  object-fit: scale-down;
  padding: 0;
  margin-right: 0.25em;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .simple-place-info .contents-wrapper .content__time {
  width: 100%;
  margin-top: 2%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info {
  width: 85%;
  height: fit-content;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper {
  width: 90%;
  height: 100%;
  font-size: 1em;
  position: relative;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper .content__bookmark {
  position: absolute;
  left: 90%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper .content__name {
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 2%;
  width: 80%;
  white-space: break-spaces;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper .content__star {
  width: 50%;
  height: 10%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper .content__star img {
  object-fit: scale-down;
  padding: 0;
  margin-right: 0.25em;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper .content__comment {
  width: 10%;
  margin-bottom: 4%;
  font-weight: 600;
  color: #5a3307;
  font-size: 0.8em;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-place-info .contents-wrapper .content__time {
  width: 100%;
  margin-top: 3%;
  font-size: 0.6em;
  margin-bottom: 3%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper {
  width: 85%;
  height: 70%;
  display: flex;
  justify-content: center;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content {
  width: 90%;
  height: 80%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__address {
  width: 90%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__address .content__address-name {
  font-size: 0.8em;
  font-weight: 600;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__address .content__address-copy {
  margin-left: 3.5%;
  font-size: 0.7em;
  padding: 2%;
  color: #a7a7a7;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__line {
  margin-top: 5.5%;
  width: 100%;
  border-top: solid #e9e9e9;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list {
  width: 90%;
  height: 100%;
  margin-top: 15%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review {
  width: 100%;
  height: 17%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.7em;
  font-weight: 600;
  margin-bottom: 20%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .pair-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5%;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .pair-content .positive-content {
  width: fit-content;
  white-space: nowrap;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .pair-content .positive-content .count {
  padding: 0.1em 0.4em 0.24em 0.4em;
  border-radius: 2em;
  background-color: #ff4c6c;
  color: white;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .pair-content .nagative-content {
  width: fit-content;
  white-space: nowrap;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .pair-content .nagative-content .count {
  padding: 0.1em 0.4em 0.24em 0.4em;
  border-radius: 2em;
  background-color: #00beca;
  color: white;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .ratio-bar {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 3em;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .ratio-bar .positive-ratio {
  height: 100%;
  background-color: #ff4c6c;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .content__review-list .pair-review .ratio-bar .nagative-ratio {
  width: fit-content;
  height: 100%;
  flex: 1 1 auto;
  background: #00beca;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .null-review-list {
  width: 90%;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .null-review-list .null-content {
  border: solid 0.21em #ff4c6c;
  padding: 3%;
  border-radius: 4em;
  font-weight: 600;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.home-wrapper .recommend-wrapper .recommend__list-wrapper .detail-content-wrapper .detail-content .content__review-wrapper .null-review-list .null-content .count {
  padding: 0.1em 0.4em 0.24em 0.4em;
  border-radius: 2em;
  background-color: #ff4c6c;
  color: white;
}
.home-wrapper .none {
  display: none;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.login-wrapper .login-logo {
  margin-bottom: 2em;
}
.login-wrapper .login-button-wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 40%;
  align-items: center;
  justify-content: center;
}
.login-wrapper .login-button-wrapper div {
  display: flex;
  flex-direction: column;
  margin: 0.6em;
  width: 90%;
  height: 2.1em;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.login-wrapper .login-button-wrapper div:first-child {
  background-color: #03c75a;
  border-radius: 5px;
}
.login-wrapper .login-button-wrapper div:last-child {
  background-color: #fee500;
  border-radius: 5px;
}

.loading-wrapper {
  margin-bottom: 12em;
}

.header-bagic {
  height: 13%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.my-review-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20%;
}
.my-review-wrapper .my-reveiw__list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
}
.my-review-wrapper .my-reveiw__list-wrapper .my-review__list {
  width: 85%;
  margin: 10%;
  position: absolute;
  top: 0;
}
.my-review-wrapper .my-reveiw__list-wrapper .my-review__list .my-review__date {
  padding: 4% 2% 4% 2%;
  font-weight: 600;
  font-size: 1.2em;
  border-bottom: solid #eeeeee;
}
.my-review-wrapper .my-reveiw__list-wrapper .my-review__list .my-review__content {
  width: 85%;
  padding: 3%;
  height: fit-content;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6% 3% 6% 3%;
}
.my-review-wrapper .my-reveiw__list-wrapper .my-review__list .my-review__content .my-review__content-wrapper {
  font-size: 0.7em;
  font-weight: 520;
  width: 100%;
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}
.review-wrapper .review__content-wrapper {
  width: 80%;
  height: 20%;
  margin-bottom: 5%;
}
.review-wrapper .review__content-wrapper .review__content__main {
  height: fit-content;
  font-size: 1.3rem;
  font-weight: 550;
  padding: 5% 5% 0 5%;
  text-align: center;
  word-break: keep-all;
  line-height: 130%;
}
.review-wrapper .review__content-wrapper .review__content__another {
  width: fit-content;
  margin-left: 55%;
  cursor: pointer;
}
.review-wrapper .review__content-wrapper .review__content__another a,
.review-wrapper .review__content-wrapper .review__content__another a:hover,
.review-wrapper .review__content-wrapper .review__content__another a:focus,
.review-wrapper .review__content-wrapper .review__content__another a:active {
  text-decoration: none;
  color: inherit;
}
.review-wrapper .review__content-wrapper .review__content__another a {
  font-size: 0.7rem;
  color: #c4c4c4;
}
.review-wrapper .review__star-wrapper {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 20%;
  align-items: center;
  margin-bottom: 20%;
}
.review-wrapper .review__star-wrapper .review__star__title {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5%;
  margin-bottom: 5%;
  color: #ff4c6c;
}
.review-wrapper .review__star-wrapper .review__star {
  height: 50%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.review-wrapper .review__star-wrapper .review__star .rating__star {
  text-align: center;
  cursor: pointer;
  width: 20%;
  font-size: 1.6rem;
  color: #ffef0f;
}
.review-wrapper .review__button-wrapper {
  width: 80%;
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.review-wrapper .review__button-wrapper .review__button__submit {
  width: 90%;
  height: 50%;
  border-radius: 1rem;
  padding: 0;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-color: #ff4c6c;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.review-wrapper .review__button-wrapper .review__button__submit a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  color: white;
  text-decoration: none;
}
.review-wrapper .review__button-wrapper .review__button__next-day {
  margin-top: 2%;
  border: none;
  padding: 1%;
  cursor: pointer;
  outline: inherit;
  background-color: rgba(255, 255, 255, 0);
  font-weight: 550;
}
.review-wrapper .review__button-wrapper .review__button__next-day a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #ff4c6c;
  text-decoration: none;
}

.review-detail-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}
.review-detail-wrapper .review-detail__title {
  font-size: 1.3rem;
  font-weight: 650;
  padding: 5%;
  color: #ff4c6c;
  margin-bottom: 5%;
}
.review-detail-wrapper .review-detail__content-wrapper {
  width: 90%;
  height: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.review-detail-wrapper .review-detail__content-wrapper > div {
  display: flex;
  flex-direction: row;
  width: 85%;
  height: fit-content;
  justify-content: space-between;
  margin: 5%;
}
.review-detail-wrapper .review-detail__content-wrapper > div button {
  width: 42%;
  border: none;
  cursor: pointer;
  outline: inherit;
  padding: 4%;
  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: rgba(196, 85, 85, 0.25) 0px 5px 15px;
  background-color: white;
  border-radius: 1rem;
  border: solid #ff4c6c 0.1rem;
}
.review-detail-wrapper .review-detail__content-wrapper .select {
  background-color: rgba(255, 76, 108, 0.9);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 19px 38px, rgba(0, 0, 0, 0.18) 0px 9px 12px;
  color: white;
}
.review-detail-wrapper .review-detail__button-wrapper {
  width: 90%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.review-detail-wrapper .review-detail__button-wrapper > button {
  width: 70%;
  height: 100%;
  border: none;
  cursor: pointer;
  outline: inherit;
  padding: 4%;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #ff4c6c;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.review-detail-wrapper .review-detail__button-wrapper > button a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  color: white;
  text-decoration: none;
}

.review-location-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.review-location-wrapper .review-location__title {
  font-size: 1.2rem;
  font-weight: 650;
  height: 10%;
  padding: 5%;
  color: #ff4c6c;
  margin-bottom: 5%;
}
.review-location-wrapper .review-location__contents-wrapper {
  width: 90%;
  height: 50%;
  overflow: scroll;
}
.review-location-wrapper .review-location__contents-wrapper .review-location__contents {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.review-location-wrapper .review-location__contents-wrapper .review-location__contents button {
  width: 70%;
  border-radius: 1rem;
  margin: 5%;
  padding: 3% 4% 3% 4%;
  border: none;
  cursor: pointer;
  outline: inherit;
  background-color: #ff4c6c;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookmark-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20%;
}
.bookmark-wrapper .bookmark__list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  position: relative;
  overflow-x: hidden;
}
.bookmark-wrapper .bookmark__list-wrapper .bookmark__list {
  width: 85%;
  margin: 10%;
  position: absolute;
  top: 0;
}
.bookmark-wrapper .bookmark__list-wrapper .bookmark__list .bookmark__content {
  width: 85%;
  padding: 3%;
  height: fit-content;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8% 3% 8% 3%;
}
.bookmark-wrapper .bookmark__list-wrapper .bookmark__list .bookmark__content .bookmark__content-wrapper {
  font-size: 0.7em;
  font-weight: 520;
  width: 100%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}
.bookmark-wrapper .bookmark__list-wrapper .bookmark__list .bookmark__content .bookmark__content-wrapper .bookmark__content__img {
  position: absolute;
  left: 90%;
  top: -26%;
}
.bookmark-wrapper .bookmark__list-wrapper .bookmark__list .bookmark__content .bookmark__content-wrapper .bookmark__content__name {
  font-weight: 600;
  margin-bottom: 3%;
}

#modal.toast {
  position: "fixted";
  bottom: "10%";
  background-color: black;
  color: white;
}

#modal.none {
  display: none;
}