@import "../component/sidebar-menubar.scss";

.home-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .map-wrapper {
    background-color: rgb(223, 223, 223);
    position: relative;
    z-index: 0;
    top: 0px;
    width: 100%;
    height: 85%;
  }

  .map__my-direction-button {
    position: absolute;
    font-weight: 600;
    font-size: 0.8em;
    top: 70%;
    margin-right: 1em;
    right: 0;
    width: 3em;
    height: 3em;
    padding: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
    }
  }

  .search-wrapper {
    width: 80%;
    height: 5%;
    padding-left: 2%;
    background-color: white;
    position: absolute;
    top: 4%;
    left: 15%;
    border-radius: 5px;
    opacity: 0.9;
    display: flex;
    justify-content: space-between;

    .search__keyword {
      width: 80%;
      height: 90%;
      border: none;
      font-size: 0.8em;
      color: black;
      margin: 0;
    }

    .search__keyword:focus {
      outline: none;
    }

    .search__keyword-button {
      border: 0;
      width: 17%;
      height: 90%;
      background-color: white;
      padding: 3%;

      img {
        width: 50%;
      }
    }
  }

  .recommend-wrapper {
    will-change: transform;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 7px;
    top: 78%;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-out;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 15px;

    .recommend__move-button {
      width: 100%;
      height: 4em;
      display: flex;
      justify-content: center;
      align-items: center;

      .recommend__move-button__icon {
        background-color: rgb(231, 227, 227);
        width: 20%;
        height: 0.25em;
        border-radius: 3px;
      }
    }
    .recommend__list-wrapper {
      margin-bottom: 10%;
      margin-top: 5%;
      width: 100%;
      height: 200%;
      display: flex;
      overflow: hidden;
      align-items: center;
      flex-direction: column;

      .simple-place-info {
        width: 85%;
        height: 12%;
        // margin-bottom: 5%;
        margin-top: 2%;
        position: relative;
        padding: 0.5em;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        background-color: rgb(247, 247, 247);
        display: flex;
        justify-content: center;
        align-items: center;
        .contents-wrapper {
          width: 100%;
          height: 100%;
          padding-top: 2%;
          padding-left: 4%;
          font-size: 0.7em;

          .content__name {
            margin-top: 2%;
            margin-bottom: 5%;
            font-weight: 600;
            font-size: 1em;
          }

          .content__comment {
            width: 10%;
            margin-bottom: 4%;
            font-weight: 600;
            color: rgb(90, 51, 7);
          }

          .content__bookmark {
            position: absolute;
            padding: 0;
            top: 0;
            left: 84%;
            img {
              object-fit: contain;
            }
          }

          .content__star {
            width: 50%;
            height: 10%;
            img {
              object-fit: scale-down;
              padding: 0;
              margin-right: 0.25em;
            }
          }
          .content__time {
            width: 100%;
            margin-top: 2%;
          }
        }
      }

      .detail-place-info {
        width: 85%;
        height: fit-content;
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        .contents-wrapper {
          width: 90%;
          height: 100%;
          font-size: 1em;
          position: relative;

          .content__bookmark {
            position: absolute;
            left: 90%;
          }

          .content__name {
            font-weight: 600;
            font-size: 1em;
            margin-bottom: 2%;
            width: 80%;
            white-space: break-spaces;
          }

          .content__star {
            width: 50%;
            height: 10%;
            img {
              object-fit: scale-down;
              padding: 0;
              margin-right: 0.25em;
            }
          }

          .content__comment {
            width: 10%;
            margin-bottom: 4%;
            font-weight: 600;
            color: rgb(90, 51, 7);
            font-size: 0.8em;
          }

          .content__time {
            width: 100%;
            margin-top: 3%;
            font-size: 0.6em;
            margin-bottom: 3%;
          }
        }
      }

      .detail-content-wrapper {
        width: 85%;
        height: 70%;
        display: flex;
        justify-content: center;

        .detail-content {
          width: 90%;
          height: 80%;

          .content__address {
            width: 90%;

            .content__address-name {
              font-size: 0.8em;
              font-weight: 600;
            }

            .content__address-copy {
              margin-left: 3.5%;
              font-size: 0.7em;
              padding: 2%;
              color: rgb(167, 167, 167);
            }
          }

          .content__line {
            margin-top: 5.5%;
            width: 100%;
            border-top: solid rgb(233, 233, 233);
          }

          .content__review-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .content__review-list {
              width: 90%;
              height: 100%;
              margin-top: 15%;

              .pair-review {
                width: 100%;
                height: 17%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 0.7em;
                font-weight: 600;
                margin-bottom: 20%;

                .pair-content {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  margin-bottom: 5%;

                  .positive-content {
                    width: fit-content;
                    white-space: nowrap;

                    .count {
                      padding: 0.1em 0.4em 0.24em 0.4em;
                      border-radius: 2em;
                      background-color: #ff4c6c;
                      color: white;
                    }
                  }

                  .nagative-content {
                    width: fit-content;
                    white-space: nowrap;
                    .count {
                      padding: 0.1em 0.4em 0.24em 0.4em;
                      border-radius: 2em;
                      background-color: #00beca;
                      color: white;
                    }
                  }
                }

                .ratio-bar {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  border-radius: 3em;
                  overflow: hidden;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
                    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

                  .positive-ratio {
                    height: 100%;
                    background-color: #ff4c6c;
                  }

                  .nagative-ratio {
                    width: fit-content;
                    height: 100%;
                    flex: 1 1 auto;
                    background: #00beca;
                  }
                }
              }
            }

            .null-review-list {
              width: 90%;
              font-size: 0.7em;
              display: flex;
              align-items: center;
              justify-content: space-around;

              .null-content {
                border: solid 0.21em #ff4c6c;
                padding: 3%;
                border-radius: 4em;
                font-weight: 600;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                .count {
                  padding: 0.1em 0.4em 0.24em 0.4em;
                  border-radius: 2em;
                  background-color: #ff4c6c;
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
  .none {
    display: none;
  }
}
